<template>
  <NavBar :appLanguage="appLanguage" @setLang="setLang" />
  <div class="app">
    <div class="router">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      appLanguage: "pl",
    };
  },
  methods: {
    setLang(newLang) {
      this.appLanguage = newLang;
      this.$i18n.locale = newLang;
      console.log(this.$i18n.locale);
    },
  },
  created() {
    // get system language
    if (navigator.language) {
      this.setLang(navigator.language.slice(0, 2));
    } else {
      this.setLang("en");
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
@import "@/styles/animations.scss";
@import "@/styles/app.scss";
</style>
