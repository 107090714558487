<template>
  <nav class="navbar">
    <span class="navbar__logo">
      <router-link
        to="/"
        data-umami-event="navbar-click"
        data-umami-event-destination="home"
        @click="scrollTo('landing')"
        class="navbar__logo"
      >
        <h1>{{ $t("company-name") }}</h1>
      </router-link>
    </span>
    <span class="navbar__links">
      <a
        href="#"
        data-umami-event="navbar-click"
        :data-umami-event-destination="link.localeString"
        v-for="link in links"
        class="navbar__link"
        :key="link.localeString"
        @click="scrollTo(link.scrollTo)"
      >
        {{ $t(`navBar.${link.localeString}`) }}
      </a>
    </span>
    <span class="navbar__language">
      <a
        href="#"
        data-umami-event="locale-change"
        data-umami-event-language="en"
        @click="selectedLanguage = 'en'"
        :class="selectedLanguage === 'en' ? 'active' : ''"
        >ENG</a
      >
      |
      <a
        href="#"
        data-umami-event="locale-change"
        data-umami-event-language="pl"
        @click="selectedLanguage = 'pl'"
        :class="selectedLanguage === 'pl' ? 'active' : ''"
        >POL</a
      >
    </span>
  </nav>
</template>

<script>
export default {
  props: { appLanguage: String },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
  data() {
    return {
      showSelector: false,
      languages: {
        en: { name: "ENG" },
        pl: { name: "POL" },
      },
      links: [
        {
          localeString: "about_me",
          scrollTo: "about-me",
        },
        {
          localeString: "my_background",
          scrollTo: "my-background",
        },
        {
          localeString: "about_clients",
          scrollTo: "about-clients",
        },
        {
          localeString: "about_sessions",
          scrollTo: "about-sessions",
        },
        {
          localeString: "booking",
          scrollTo: "booking",
        },
        {
          localeString: "schedule",
          scrollTo: "schedule",
        },
        {
          localeString: "contact",
          scrollTo: "contact",
        },
      ],
    };
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.appLanguage;
      },
      set(newLang) {
        this.$emit("setLang", newLang);
      },
    },
    selectedLanguageFlag() {
      return this.languages[this.selectedLanguage].flag;
    },
  },
};
</script>
<style scoped lang="scss">
$text: #200221;
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #e9c6c0;
  height: 90px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h1 {
    margin: 0;
    font-size: 1.5rem;
  }

  a {
    color: $text;

    &:hover,
    &:active,
    &:visited {
      color: $text;
    }
  }

  .navbar__logo {
    text-decoration: none;
    color: $text;
    text-transform: uppercase;
  }

  .navbar__links {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 4rem;
    // if width is less than 1500px then hide the links
    @media (max-width: 1500px) {
      display: none;
    }

    .navbar__link {
      text-decoration: none;
      font-size: 1.25rem;
    }
  }

  .navbar__language {
    display: flex;
    gap: 0.5rem;

    a {
      cursor: pointer;
      text-decoration: none;
    }

    .active {
      text-decoration: underline;
    }
  }
}
</style>
